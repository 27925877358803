import { Controller } from "@hotwired/stimulus"
import svgPanZoom from "svg-pan-zoom"

export default class extends Controller {
  static targets = [ "map" ]

  connect() {
    this.map = svgPanZoom(this.mapTarget, {
      center: true,
      fit: true,
      zoomEnabled: false,
      zoomScaleSensitivity: 0.25,
      minZoom: 0.5,
      maxZoom: 1.5,
    })
  }

  disconnect() {
    this.map.destroy()
  }

  zoomIn() {
    this.map.zoomIn()
  }

  zoomOut() {
    this.map.zoomOut()
  }

  reset() {
    this.map.reset()
  }
}
